import "./App.css";
import Logo from "./components/logo";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Logo />
        <h1>Hello, I am Lili</h1>
        <Logo />
      </header>
    </div>
  );
}

export default App;
